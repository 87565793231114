import { z } from "zod";

export const Permissions = z.enum(
    [
        "default",
        "admin",
        "support",
        "support_head",
        "media_buyer",
        "product_lead",
        "additionalsubs",
        "tiktok",
        "newsletter",
        "none",
        "dev",
        "google",
        "monitoring",
        "realtime_v3",
        
    ]
);
type PermissionsEnum = z.infer<typeof Permissions>;

export const checkPermission = (userState: any, permission: PermissionsEnum) => {
    return userState.scopes.indexOf(permission) >= 0;
}

export const getPermissionTitle = (permission: PermissionsEnum): string => {
    switch (permission) {
        case Permissions.Enum.default:
            return 'Default';
        case Permissions.enum.admin:
            return 'Admin';
        case Permissions.enum.support:
            return 'Support';
        case Permissions.enum.support_head:
            return 'Support Head';
        case Permissions.enum.media_buyer:
            return 'Media Buyer';
        case Permissions.enum.product_lead:
            return 'Product Lead';
        case Permissions.enum.additionalsubs:
            return 'Additional subs';
        case Permissions.enum.tiktok:
            return 'Tiktok';
        case Permissions.enum.newsletter:
            return 'Newsletter';
        case Permissions.enum.none:
            return 'None';
        case Permissions.enum.dev:
            return 'DEV';
        case Permissions.enum.google:
            return 'Google';
        case Permissions.enum.monitoring:
            return 'Monitoring';
        case Permissions.enum.realtime_v3:
            return 'Realtime V3';
    }
}