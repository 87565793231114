import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import React, { Fragment, useEffect, useState } from 'react'
import PlanFilter from './Components/PlanFilter'
import CountryGroupFilter, { CountryGroupFilterGroup } from './Components/CountryGroupFilter'
import CountryFilter, { CountryFilterCountry } from './Components/CountryFilter'
import GenderFilter from './Components/GenderFilter'
import AngleFilter from './Components/AngleFilter'
import FunnelFilter from './Components/FunnelFilter'
import LanguageFilter from './Components/LanguageFilter'
import { Compact, CompactRow, FieldDateRange, FieldLabel } from '@newageerp/v3.bundles.form-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import moment from 'moment'
import { useLocalStorage, useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle'
import { LogoLoader, Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle'
import classNames from 'classnames'
import { Tooltip } from "@newageerp/v3.bundles.badges-bundle";
import SubPeriodFilter from './Components/SubPeriodFilter'

export default function DashboardRefunds() {
    const t = (e: string) => e;

    const [lang, setLang] = useState('');
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);
    const [angle, setAngle] = useState('');
    const [gender, setGender] = useState('');
    const [countries, setCountries] = useState<string[]>([]);
    const [countryGroup, setCountryGroup] = useState(0);
    const [plan, setPlan] = useState<string>('');
    const [type, setType] = useState('refunds');

    const defDates = {
        dateFrom: '2022-12-01',
        dateTo: moment().add(1, 'day').format("YYYY-MM-DD")
    };
    const [chartDates, setChartDates] = useState(defDates);
    const clearState = () => {
        setChartDates(defDates)
    }

    const [countriesStats, setCountriesStats] = useState([]);
    const [churnCountriesStats, setChurnCountriesStats] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [dropsOn, setDropsOn] = useState([]);

    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);

    const [reqData, reqDataParams] = useURequest("/app/plugins/ProductPerformance/refundsData");
    const loadData = () => {
        reqData({
            dateFrom: chartDates.dateFrom,
            dateTo: chartDates.dateTo,
            lang,
            funnels,
            countries,
            plan,
            gender,
        }).then((res: any) => {
            setCountriesStats(res.data.countries);
            setChurnCountriesStats(res.data.countries);

            setPeriods(res.data.periods);
            setDropsOn(res.data.dropsOn);
        })
    }

    const borderLeft = 'border-l border-blue-400';
    const borderRight = 'border-r border-blue-400';

    const groupByTabs = [
        {
            title: 'Refunds',
            key: 'refunds'
        },
        {
            title: 'Subscribers drop rate',
            key: 'drop'
        },
        {
            title: <Fragment>{'Subscribers churn rate'} <Tooltip text='Works correctly only with data of one month' /></Fragment>,
            key: 'churn'
        },
        {
            title: <Fragment>{'Recurring churn rate'} </Fragment>,
            key: 'churn_subs'
        }
    ]


    return (
        <Fragment>
            <div className='space-y-4'>
                <WhiteCard isCompact={true} className='space-y-2'>
                    <div className='flex flex-wrap gap-x-4 gap-y-1 items-end'>
                        <Compact>
                            <CompactRow
                                label={<FieldLabel>{t('Date')}</FieldLabel>}
                                control={
                                    <FieldDateRange dates={chartDates} setDates={setChartDates} onClear={clearState} compact={true} />
                                }
                            />
                        </Compact>

                        <LanguageFilter
                            lang={lang}
                            setLang={setLang}
                        />

                        <FunnelFilter
                            funnels={funnels}
                            setFunnels={setFunnels}
                        />

                        <AngleFilter
                            angle={angle}
                            setAngle={setAngle}
                            funnels={funnels}
                            setFunnels={setFunnels}
                        />

                        <GenderFilter
                            gender={gender}
                            setGender={setGender}
                        />

                        <CountryFilter
                            countries={countries}
                            setCountries={setCountries}
                            countriesData={countriesData.data.data}
                        />

                        <CountryGroupFilter
                            countryGroup={countryGroup}
                            setCountryGroup={setCountryGroup}
                            countryGroupsData={countryGroupsData.data.data}

                            setCountries={setCountries}
                            countriesData={countriesData.data.data}
                        />

                        <SubPeriodFilter
                            period={plan}
                            setPeriod={setPlan}
                        />
                    </div>

                    <div>
                        <MainButton color={"sky"} iconName='filter' disabled={reqDataParams.loading} onClick={loadData}>Filter</MainButton>
                    </div>
                </WhiteCard>

                {reqDataParams.loading && <LogoLoader size={60} />}

                <div className="w-full">
                    <div className={classNames('border-b', 'border-slate-200')}>
                        {groupByTabs.map((t) => {
                            const active = t.key === type;
                            return (
                                <button
                                    className={
                                        classNames(
                                            'text-sm',
                                            { 'font-semibold': active },
                                            'px-[16px]',
                                            'py-[8px]',
                                            { 'text-sky-500': active },
                                            { 'text-slate-500': !active },
                                        )
                                    }
                                    onClick={() => setType(t.key)}
                                    style={active ? { boxShadow: 'inset 0px -2px 0px 0px #0EA5E9' } : undefined}
                                >
                                    {t.title}
                                </button>
                            )
                        })}
                    </div>
                </div>

                {!!countriesStats && countriesStats.length > 0 && type === 'refunds' &&
                    <WhiteCard>
                        <Table
                            thead={<thead>
                                <tr>
                                    <Th>Country</Th>

                                    <Th className={borderLeft} textAlignment='text-right'>Subscriptions</Th>
                                    <Th textAlignment='text-right'>Refunds</Th>

                                    <Th className={borderLeft} textAlignment='text-right'>Upsells</Th>
                                    <Th textAlignment='text-right'>Refunds</Th>

                                    <Th className={borderLeft} textAlignment='text-right'>Gross</Th>
                                    <Th textAlignment='text-right'>Refunds</Th>
                                    <Th textAlignment='text-right'>Rate</Th>
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {countriesStats.map((c: any, cId: number) => {
                                        return <tr key={`rep-${cId}`} className={classNames({ 'total-row font-bold': c.country === 'ALL' })}>
                                            <Td>
                                                {c.country}
                                            </Td>

                                            <Td className={borderLeft} textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.grossSub,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.refundsSub,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>

                                            <Td className={borderLeft} textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.grossUp,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.refundsUp,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>

                                            <Td className={borderLeft} textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.gross,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.refunds,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='text-right font-medium'>
                                                {numberFormat({
                                                    userInput: c.rate,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}%</Td>
                                        </tr>
                                    })}
                                </tbody>
                            }

                        />
                    </WhiteCard>
                }

                {!!countriesStats && countriesStats.length > 0 && type === 'drop' &&
                    <WhiteCard>
                        <Table
                            thead={<thead>
                                <tr>
                                    <Th>Country</Th>

                                    {dropsOn.map(p => {
                                        return <Th textAlignment='text-right' key={`t-${p}`}>{p + 1}</Th>
                                    })}
                                    <Th className={borderLeft} textAlignment='text-right'>Users</Th>
                                    <Th textAlignment='text-right'>Dropped</Th>
                                    <Th textAlignment='text-right'>All</Th>
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {churnCountriesStats.map((c: any, cId: number) => {
                                        return <tr key={`repc-${cId}`} className={classNames({ 'total-row font-bold': c.country === 'ALL' })}>
                                            <Td>
                                                {c.country}
                                            </Td>

                                            {dropsOn.map(p => {
                                                let churn = 0;

                                                if (c.users[p] > 0) {
                                                    churn = (c.churn[p] / c.users[p]) * 100;
                                                }

                                                const v = numberFormat({
                                                    userInput: churn,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                });

                                                return <Td textAlignment='text-right' key={`repc-${cId}t-${p}`}>
                                                    <span  className={
                                                    classNames({
                                                        'text-xs text-gray-500': v === '0.00'
                                                    })
                                                }>{v}%</span>
                                                </Td>
                                            })}
                                            <Td className={borderLeft} textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.users.all,
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.churn.all,
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='text-right font-medium'>{c.dropRate.toFixed(2)}%</Td>
                                        </tr>
                                    })}
                                </tbody>
                            }

                        />
                    </WhiteCard>
                }

                {!!countriesStats && countriesStats.length > 0 && type === 'churn' &&
                    <WhiteCard>
                        <Table
                            thead={<thead>
                                <tr>
                                    <Th>Country</Th>

                                    {dropsOn.map(p => {
                                        return <Th textAlignment='text-right' key={`t-${p}`}>{p + 1}</Th>
                                    })}
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {churnCountriesStats.map((c: any, cId: number) => {
                                        return <tr key={`repc-${cId}`} className={classNames({ 'total-row font-bold': c.country === 'ALL' })}>
                                            <Td>
                                                {c.country}
                                            </Td>

                                            {dropsOn.map(p => {
                                                return <Td textAlignment='text-right' key={`repc-${cId}t-${p}`}>
                                                    {numberFormat({
                                                        userInput: c.churnRate[p],
                                                        decimals: 2,
                                                        decPoint: '.',
                                                        thousandsSep: ' '
                                                    })}%
                                                </Td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            }

                        />
                    </WhiteCard>
                }

                {!!countriesStats && countriesStats.length > 0 && type === 'churn_subs' &&
                    <WhiteCard>
                        <Table
                            thead={<thead>
                                <tr>
                                    <Th>Country</Th>
                                    <Th className={borderRight} textAlignment='text-right'>Users</Th>

                                    {dropsOn.map(p => {
                                        return <Th textAlignment='text-right' key={`t-${p}`}>{p + 1}</Th>
                                    })}
                                </tr>
                            </thead>}
                            tbody={
                                <tbody>
                                    {churnCountriesStats.map((c: any, cId: number) => {
                                        return <tr key={`repc-${cId}`} className={classNames({ 'total-row font-bold': c.country === 'ALL' })}>
                                            <Td>
                                                {c.country}
                                            </Td>
                                            <Td className={borderRight} textAlignment='text-right'>
                                                {numberFormat({
                                                    userInput: c.users.all,
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>

                                            {dropsOn.map(p => {
                                                return <Td textAlignment='text-right' key={`repc-${cId}t-${p}`} title={c.netSubscriptionsStart[p][p].toFixed(2)}>
                                                    {numberFormat({
                                                        userInput: c.subsRate[p],
                                                        decimals: 2,
                                                        decPoint: '.',
                                                        thousandsSep: ' '
                                                    })}%<br />
                                                    <span className='text-slate-600 text-xs'>
                                                        {numberFormat({
                                                            userInput: c.netSubscriptionsStart[p][p].toFixed(2),
                                                            decimals: 2,
                                                            decPoint: '.',
                                                            thousandsSep: ' '
                                                        })} USD
                                                    </span>
                                                </Td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            }

                        />
                    </WhiteCard>
                }
            </div>
        </Fragment>
    )
}
