import { Compact, CompactRow, FieldLabel, FieldSelect } from '@newageerp/v3.bundles.form-bundle'
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import React, { useEffect, useState } from 'react'

type Props = {
    angle: string,
    setAngle: (v: string) => void,
    funnels: string[],
    setFunnels: (v: string[]) => void,

}

export default function AngleFilter(props: Props) {
    const t = (e: string) => e;

    const { angle, setAngle } = props;

    const { funnels, setFunnels } = props;
    const [angles, setAngles] = useState<string[]>([]);
    const [allFunnels, setAllFunnels] = useState<string[]>([]);

    const [doReq, reqData] = useURequest<string>("/app/plugins/Reports/ReportsFilter/slugs");

    useEffect(() => {
        doReq({}).then((d: any) => {
            const _angles: string[] = [];
            setAllFunnels(d.data.data);

            for (const x of d.data.data) {
                const xPart = x.split("/");
                if (xPart.length > 1) {
                    if (_angles.indexOf(xPart[0]) === -1) {
                        _angles.push(xPart[0]);
                    }
                }
            }
            setAngles(_angles)
        })
    }, []);

    useEffect(() => {
        if (angle) {
            setFunnels([...allFunnels.filter(x => x.indexOf(`${angle}/`) > -1)]);
        } else {
            setFunnels([])
        }
    }, [angle]);

    return (
        <Compact>
            <CompactRow
                label={<FieldLabel>{t('Angle')}</FieldLabel>}
                control={
                    <FieldSelect
                        value={angle}
                        onChange={setAngle}
                        options={[
                            {
                                label: "All",
                                value: "",
                            },
                            ...angles.map(e => ({ label: e, value: e }))
                        ]}
                    />
                }
            />
        </Compact>
    )
}
