import { Button, Card, Form, MP, Table } from '@newageerp/crm-ui'
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { RealtimeV3DateSelection } from '../RealtimeV3/types';
import { usageChartConfig, usageTypeChartConfig } from './chart-config.types';
import { usageFilter } from './filters';
import classNames from 'classnames';
import { DashboardGrossV2borderRight } from '../DashboardGrossV2';
import { useLocalStorage, useUList } from '@newageerp/v3.bundles.hooks-bundle';
import CountryFilter, { CountryFilterCountry } from '../Components/CountryFilter';
import CountryGroupFilter, { CountryGroupFilterGroup } from '../Components/CountryGroupFilter';
import { Compact, CompactRow, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import FunnelFilter from '../Components/FunnelFilter';
import AdSourceFilter from '../Components/AdSourceFilter';
import SubPeriodFilter from '../Components/SubPeriodFilter';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const tbl = Table.default;

type UsageResp = {
    calendar_week_year: string,
    order_id_countDistinct: number,
}
type UsageTypeResp = {
    calendar_week_year: string,
    action: string,
    order_id_countDistinct: number,
}


type DataItem = {
    week: any,
    allUsage: number,
    allUsage07: number,
    allUsage821: number,
    allUsage22: number,

    [key: string]: number
}

const crFunc = (a: number, b: number) => Math.round(((a > 0 ? b / a * 100 : 0)) * 100) / 100;
const dataSum = (data: any[], k: string) => data.map(el => el[k]).reduce((a, b) => a + b, 0)

export default function SubscriptionsReport2Body() {
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<DataItem[]>([]);
    const [types, setTypes] = useState<string[]>([]);

    const dtNow = moment().utcOffset('-0700');

    const defDates = {
        dateFrom: dtNow.clone().subtract(30, 'days').format('YYYY-MM-DD'),
        dateTo: dtNow.format('YYYY-MM-DD'),
    };

    const clearState = () => {
        setDates(defDates);
    }
    const [dates, setDates] = useState<RealtimeV3DateSelection>(defDates);

    const [countries, setCountries] = useState<string[]>([]);
    const [adSources, setAdSources] = useLocalStorage('DashboardAdSourceFilter', []);
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);
    const [plan, setPlan] = useState('');

    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    const [countryGroup, setCountryGroup] = useState(0);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);


    const [doReqUsage] = MP.useMpChartData<UsageResp>({
        config: usageChartConfig,
        dataSource: 'crm.view_app_usage',
        fields: ['calendar_week_year']
    });

    const [doReqUsage07] = MP.useMpChartData<UsageResp>({
        config: usageChartConfig,
        dataSource: 'crm.view_app_usage',
        fields: ['calendar_week_year']
    });
    const [doReqUsage821] = MP.useMpChartData<UsageResp>({
        config: usageChartConfig,
        dataSource: 'crm.view_app_usage',
        fields: ['calendar_week_year']
    });
    const [doReqUsageType] = MP.useMpChartData<UsageTypeResp>({
        config: usageTypeChartConfig,
        dataSource: 'crm.view_app_usage',
        fields: ['calendar_week_year', 'action']
    });

    const loadData = async () => {
        setIsLoading(true);

        const resUsage = await doReqUsage(usageFilter({ dates, countries, funnels, plan: [plan] }));
        const usageData: UsageResp[] = resUsage.data.data;

        const resUsage07 = await doReqUsage07(usageFilter({ dates, countries, funnels, plan: [plan], registrationDates: { from: 0, to: 0 } }));
        const usageData07: UsageResp[] = resUsage07.data.data;

        const resUsage821 = await doReqUsage821(usageFilter({ dates, countries, funnels, plan: [plan], registrationDates: { from: 1, to: 2 } }));
        const usageData821: UsageResp[] = resUsage821.data.data;

        const resUsageType = await doReqUsageType(usageFilter({ dates, countries, funnels, plan: [plan] }));
        const usageTypeData: UsageTypeResp[] = resUsageType.data.data;


        const _types = [
            ...usageTypeData.map((e) => e.action),
        ]
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort();
        setTypes(_types);

        const _weeks = [
            ...usageData.map((e) => (e.calendar_week_year)),
        ]
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort((a, b) => (a === b ? 0 : (a < b ? 1 : -1)));

        setData(
            _weeks.map((w: string): DataItem => {
                const wStr = w;
                const allUsage = usageData.filter((e) => e.calendar_week_year === wStr).map(e => e.order_id_countDistinct).reduce((a, b) => a + b, 0);
                const allUsage07 = usageData07.filter((e) => e.calendar_week_year === wStr).map(e => e.order_id_countDistinct).reduce((a, b) => a + b, 0);
                const allUsage821 = usageData821.filter((e) => e.calendar_week_year === wStr).map(e => e.order_id_countDistinct).reduce((a, b) => a + b, 0);
                const allUsage22 = allUsage - allUsage07 - allUsage821;

                const el: any = {
                    week: w,

                    allUsage,
                    allUsage07,
                    allUsage821,
                    allUsage22
                }

                for (const t of _types) {
                    el[t] = usageTypeData.filter((e) => e.calendar_week_year === wStr && e.action === t).map(e => e.order_id_countDistinct).reduce((a, b) => a + b, 0);
                }

                return el;
            })
        );

        setIsLoading(false);
    }

    useEffect(() => {
        loadData().catch(console.error)
    }, []);

    return (
        <div className='space-y-4'>
            <Card.WhiteCard>
                <div className='flex flex-wrap gap-x-4 gap-y-2 items-end'>
                    <Compact>
                        <CompactRow
                            label={<FieldLabel>{('Date')}</FieldLabel>}
                            control={
                                <Form.DateRangeInput dates={dates} setDates={setDates} onClear={clearState} compact={true} />
                            }
                        />
                    </Compact>
                    <CountryFilter
                        countries={countries}
                        countriesData={countriesData.data.data}
                        setCountries={setCountries}
                    />
                    <CountryGroupFilter
                        countriesData={countriesData.data.data}
                        countryGroup={countryGroup}
                        countryGroupsData={countryGroupsData.data.data}
                        setCountries={setCountries}
                        setCountryGroup={setCountryGroup}
                    />

                    <FunnelFilter
                        funnels={funnels}
                        setFunnels={setFunnels}
                    />
                    {/* 
                    <AdSourceFilter
                        adSources={adSources}
                        setAdSources={setAdSources}
                    /> */}

                    <SubPeriodFilter
                        period={plan}
                        setPeriod={setPlan}
                    />

                </div>
                <div>
                    <Button.FormButton color={"sky"} iconName='filter' disabled={isLoading} onClick={loadData}>Filter</Button.FormButton>
                </div>
            </Card.WhiteCard>

            <Card.WhiteCard>
                {isLoading && <Skeleton count={5} />}
                {!isLoading &&
                    <tbl.Table
                        thead={
                            <thead>
                                <tr>
                                    <tbl.Th>Week</tbl.Th>
                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>Active (total)</tbl.Th>
                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>Active (0-7d)</tbl.Th>

                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>Active (8-21d)</tbl.Th>

                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>Active (22d+)</tbl.Th>

                                    {types.map(t => <Fragment key={t}>
                                        <tbl.Th props={{ className: 'text-right' }}>{t}</tbl.Th>
                                        <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>%</tbl.Th>
                                    </Fragment>)}

                                </tr>
                            </thead>
                        }
                        tbody={
                            <tbody>
                                {data.map(el => {
                                    return (<tr key={`w-${el.week}`}>
                                        <tbl.Td>{el.week}</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.allUsage}</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(el.allUsage, el.allUsage07)}%</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(el.allUsage, el.allUsage821)}%</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(el.allUsage, el.allUsage22)}%</tbl.Td>

                                        {types.map(t => {
                                            const v = t in el ? el[t] : 0;
                                            return (
                                                <Fragment key={`w-${el.week}-${t}`}>
                                                    <tbl.Td props={{ className: 'text-right' }}>{v}</tbl.Td>
                                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(el.allUsage, v)}%</tbl.Td>

                                                </Fragment>
                                            )
                                        })}

                                        {/* <tbl.Td props={{ className: 'text-right' }}>{el.registered}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.registeredCr}%</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{el.cancelations}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.cancelationsCr}%</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{el.refunds}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.refundsCr}%</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{el.chargeBacks}</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{el.chargeBacksCr}%</tbl.Td> */}
                                    </tr>)
                                })}
                            </tbody>
                        }
                    />
                }
            </Card.WhiteCard>
        </div>
    )
}
