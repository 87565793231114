import { requests } from "@newageerp/mp-types";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useEffect, useState } from "react";
import { Table } from '@newageerp/crm-ui'
import classNames from "classnames";

const tbl = Table.default;

type Props = {
    orderId: string,
}

type PaymentResponse = {
    id: number,
    source: string,
    payment_psp: {
        psp_id: string,
        psp_name: string,
    },
    payment_orchestrator: {
        orchestrator_id: string,
        orchestrator_name: string,
    }
}

type PrimerResponse = {
    source_id: string,
    last4_digits: string,
    first6_digits: string,
    cardholder_name: string,
    processor: string,
    network: string,
    payment_type: string,
    payment_method_type: string,
    payment_method_token: string,
}

type StripeResponse = {
    source_id: string,
    card_last4: string,
    wallet_last4: string,
    auth_code: string,
    card_brand: string,
    card_network: string,
    wallet_type: string,
    payment_method_type: string,

}

export const ReportItemPayments = (props: Props) => {
    const { system: dsSystem } = useDataCache();

    const resultOptions = {
        authKey: dsSystem.data.settings.mp.apiKey,
        baseUrl: dsSystem.data.settings.mp.baseUrl,
    }

    const [payments, setPayments] = useState<PaymentResponse[]>([]);
    const [primerData, setPrimerData] = useState<PrimerResponse[]>([]);
    const [stripeData, setStripeData] = useState<StripeResponse[]>([]);

    const getPaymentsData = async () => {
        const resp = await requests.results.get<PaymentResponse>({
            dataSource: 'crm.payment',
            fields: [
                'id',
                'source',
                'payment_psp.psp_id',
                'payment_psp.psp_name',
                'payment_orchestrator.orchestrator_id',
                'payment_orchestrator.orchestrator_name',
            ],
            dataFilter: [
                {
                    comparison: "and",
                    filters: [
                        {
                            comparison: "and",
                            filters: [
                                {
                                    class: "StringEqualFilter",
                                    props: {
                                        column: 'main_order_id',
                                        value: props.orderId
                                    }
                                }
                            ]
                        }
                    ]
                }
            ],
            options: resultOptions
        })
        setPayments(resp.data);

        const stripeIds = [];
        const primerIds = [];
        for (const el of resp.data) {
            if (el.payment_orchestrator && el.payment_orchestrator.orchestrator_name === 'primer') {
                primerIds.push(el.payment_orchestrator.orchestrator_id);
            }
            if (el.payment_psp && el.payment_psp.psp_name === 'stripe') {
                stripeIds.push(el.payment_psp.psp_id);
            }
        }

        if (primerIds.length > 0) {
            const primerRes = await requests.results.get<PrimerResponse>({
                dataSource: 'crm-primer.primer_payment',
                fields: ['source_id', 'last4_digits', 'first6_digits', 'cardholder_name', 'processor', 'network', 'payment_type', 'payment_method_type', 'payment_method_token',],
                dataFilter: [
                    {
                        comparison: "and",
                        filters: [
                            {
                                comparison: "and",
                                filters: [
                                    {
                                        class: "DataInFilter",
                                        props: {
                                            column: 'source_id',
                                            value: primerIds.join("\n")
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ],
                options: resultOptions
            })
            setPrimerData(primerRes.data)
        }

        if (stripeIds.length > 0) {
            const stripeRes = await requests.results.get<StripeResponse>({
                dataSource: 'crm-stripe.stripe_charge',
                fields: ['source_id', 'card_last4', 'wallet_last4', 'auth_code', 'card_brand', 'card_network', 'wallet_type', 'payment_method_type'],

                dataFilter: [
                    {
                        comparison: "and",
                        filters: [
                            {
                                comparison: "and",
                                filters: [
                                    {
                                        class: "DataInFilter",
                                        props: {
                                            column: 'source_id',
                                            value: stripeIds.join("\n")
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ],
                options: resultOptions
            })
            setStripeData(stripeRes.data)
        }
    }

    useEffect(() => {
        getPaymentsData().catch(console.error);
    }, []);


    return <tbl.Table
        thead={
            <thead>
                <tr>
                    <tbl.Th>ID</tbl.Th>
                    <tbl.Th>Source</tbl.Th>

                    <tbl.Th>PSP</tbl.Th>
                    <tbl.Th>PSP ID</tbl.Th>

                    <tbl.Th>Orchestrator</tbl.Th>
                    <tbl.Th>Orchestrator ID</tbl.Th>

                    <tbl.Th>Primer data</tbl.Th>
                    <tbl.Th>Stripe data</tbl.Th>
                </tr>
            </thead>
        }
        tbody={
            <tbody>
                {payments.map(p => {
                    const primerEl = primerData.find(e => e.source_id === p.payment_orchestrator?.orchestrator_id);
                    const stripeEl = stripeData.find(e => e.source_id === p.payment_psp?.psp_id)

                    return <tr key={`p-${p.id}`}>
                        <tbl.Td>{p.id}</tbl.Td>
                        <tbl.Td>{p.source}</tbl.Td>
                        <tbl.Td>{p.payment_psp?.psp_name}</tbl.Td>
                        <tbl.Td>{p.payment_psp?.psp_id}</tbl.Td>
                        <tbl.Td>{p.payment_orchestrator?.orchestrator_name}</tbl.Td>
                        <tbl.Td>{p.payment_orchestrator?.orchestrator_id}</tbl.Td>
                        <tbl.Td>
                            {!!primerEl && <div className={classNames(
                                'divide-y divide-slate-300',
                                '[&>div]:py-2',

                                '[&>div]:flex [&>div]:gap-2',
                                '[&>div>label]:font-medium [&>div>label]:w-[150px]'
                            )}>
                                <div>
                                    <label>Last 4 digits:</label>
                                    <p>{primerEl.last4_digits}</p>
                                </div>
                                <div>
                                    <label>First 6 digits:</label>
                                    <p>{primerEl.first6_digits}</p>
                                </div>
                                <div>
                                    <label>Cardholder name:</label>
                                    <p>{primerEl.cardholder_name}</p>
                                </div>
                                <div>
                                    <label>Network:</label>
                                    <p>{primerEl.network}</p>
                                </div>
                                <div>
                                    <label>Payment type:</label>
                                    <p>{primerEl.payment_type}</p>
                                </div>
                                <div>
                                    <label>Method:</label>
                                    <p>{primerEl.payment_method_type}</p>
                                </div>
                                <div>
                                    <label>Token:</label>
                                    <p>{primerEl.payment_method_token}</p>
                                </div>
                            </div>}
                        </tbl.Td>
                        <tbl.Td>
                        {!!stripeEl && <div className={classNames(
                                'divide-y divide-slate-300',
                                '[&>div]:py-2',

                                '[&>div]:flex [&>div]:gap-2',
                                '[&>div>label]:font-medium [&>div>label]:w-[150px]'
                            )}>
                                <div>
                                    <label>Last 4 digits:</label>
                                    <p>{stripeEl.card_last4}</p>
                                </div>
                                <div>
                                    <label>Wallet last 4 digits:</label>
                                    <p>{stripeEl.wallet_last4}</p>
                                </div>
                                <div>
                                    <label>Auth code:</label>
                                    <p>{stripeEl.auth_code}</p>
                                </div>
                                <div>
                                    <label>Card brand:</label>
                                    <p>{stripeEl.card_brand}</p>
                                </div>
                                <div>
                                    <label>Card network:</label>
                                    <p>{stripeEl.card_network}</p>
                                </div>
                                <div>
                                    <label>Wallet type:</label>
                                    <p>{stripeEl.wallet_type}</p>
                                </div>
                                <div>
                                    <label>Method type:</label>
                                    <p>{stripeEl.payment_method_type}</p>
                                </div>
                            </div>}
                        </tbl.Td>
                    </tr>
                })}
            </tbody>
        }
    />
}

